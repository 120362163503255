import * as React from "react";
import { Toaster } from "react-hot-toast";
import { ToastPosition } from "react-hot-toast/src/core/types";

type Props = {
  position?: ToastPosition;
};
const StandardToaster: React.FC<Props> = ({ position }) => (
  <Toaster
    toastOptions={{
      className: "border-white border-2 shadow-lg",
      style: {
        color: "white",
        backgroundColor: "#101628",
      },
      error: {
        className: "border-transparent border-2 shadow-lg",
        style: {
          backgroundColor: "#ff8080",
        },
      },
    }}
    position={position || "bottom-center"}
  />
);
export default StandardToaster;
